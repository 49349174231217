import { ParseObservable } from './parse-observable';
import { Injectable } from '@angular/core';

import { Observable, Observer, of } from 'rxjs';

import { Category } from './../data';
import { CategoryQuery } from './../queries';
import { flatMap, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class CategoryService {
  private _find: Observable<Category[]>;
  private _tree: Observable<Category[]>;
  private _firstMain: Observable<Category>;
  private _secondMain: Observable<Category>;
  private _thirdMain: Observable<Category>;

  constructor() {

  }

  public get(id: string): Observable<Category> {
    return this.tree().pipe(flatMap(parents => {
      let category;

      parents.forEach(p => {
        if (p.id == id) {
          category = p;
        } else {
          let children = <Category[]> p['children'];

          if (children) {
            let find = children.find(c => c.id == id);

            if (find) {
              category = find;
            }
          }
        }
      })

      return of(category);
    }))
  }

  public find() {
    if (!this._find) {
      let query = new CategoryQuery()
          .include(Category.PARENT_KEY)
          .include(Category.IMAGE_KEY)
          .equalTo(Category.ENABLED_KEY, true)
          .ascending(Category.SORT_ORDER_KEY);

      this._find = query.rx().find().pipe(publishReplay(1)).pipe(refCount());
    }
    return this._find;
  }

  public tree() {
    if (!this._tree) {
      this._tree = this.find().pipe(map(arr => {
        return arr.filter(c => !c.parent).map(c => {
          c['children'] = arr.filter(o => o.parent && o.parent.id === c.id);
          return c;
        });
      })).pipe(publishReplay(1)).pipe(refCount());
    }
    return this._tree;
  }

  public firstMain() {
    if (!this._firstMain) {
      this._firstMain = this.tree().pipe(map(c => c[0]));
    }
    return this._firstMain;
  }

  public secondMain() {
    if (!this._secondMain) {
      this._secondMain = this.tree().pipe(map(c => c[1]));
    }
    return this._secondMain;
  }

  public thirdMain() {
    if (!this._thirdMain) {
      this._thirdMain = this.tree().pipe(map(c => c[2]));
    }
    return this._thirdMain;
  }
}
