import { ParseObject } from './parse-object';

import { User } from './user';

import * as fileExtension  from 'file-extension';

import * as _ from 'lodash';

import { Temporary } from './temporary';

// console.log(fileExtension);

export class FileRef extends ParseObject implements Temporary {
  public static readonly CLASS_NAME = 'FileRef';
  public static readonly SIZE_KEY = 'size';
  public static readonly PATH_KEY = 'path';
  public static readonly NAME_KEY = 'name';
  public static readonly TYPE_KEY = 'type';
  public static readonly FILE_KEY = 'file';
  public static readonly PARENT_KEY = 'parent';
  public static readonly TEMPORARY_KEY = 'temporary';
  public static readonly USER_KEY = 'user';
  public static readonly EXTENSION_KEY = 'extension';

  public static readonly NO_IMAGE_PATH = '/assets/img/no-image-thumbnail.png';

  public static readonly TYPE_FOLDER = 'folder';

  public static readonly PATH_PRODUCT = 'product';
  public static readonly PATH_PAGE = 'page';
  public static readonly PATH_ROOT = '';

  public _localUrl: string;

  constructor() {
    super(FileRef.CLASS_NAME);
    this.temporary = false;
  }

  public get user(): User {
    return this.get(FileRef.USER_KEY);
  }

  public set user(user: User) {
    this.set(FileRef.USER_KEY, user);
  }

  public get temporary(): boolean {
    return this.get(FileRef.TEMPORARY_KEY);
  }

  public set temporary(temporary: boolean) {
    this.set(FileRef.TEMPORARY_KEY, temporary);
  }

  public get name(): string {
    return this.get(FileRef.NAME_KEY);
  }

  public set name(name: string) {
    this.set(FileRef.NAME_KEY, name);
    this.updatePath();
  }

  public get path(): string {
    return this.get(FileRef.PATH_KEY);
  }

  public set path(path: string) {
    this.set(FileRef.PATH_KEY, path);
  }

  public get size(): number {
    return this.get(FileRef.SIZE_KEY);
  }

  public set size(size: number) {
    this.set(FileRef.SIZE_KEY, size);
  }

  public get type(): string {
    return this.get(FileRef.TYPE_KEY);
  }

  public set type(type: string) {
    this.set(FileRef.TYPE_KEY, type);
  }

  public get extension(): string {
    return this.get(FileRef.EXTENSION_KEY);
  }

  public set extension(extension: string) {
    this.set(FileRef.EXTENSION_KEY, extension);
  }

  public get parent(): FileRef {
    return this.get(FileRef.PARENT_KEY);
  }

  public set parent(parent: FileRef) {
    this.set(FileRef.PARENT_KEY, parent);
    this.updatePath();
  }

  public get parentPath() {
    return this.parent ? this.parent.path : '';
  }

  public get isDir() {
    return this.type == FileRef.TYPE_FOLDER;
  }

  public get file(): Parse.File {
    return this.get(FileRef.FILE_KEY);
  }

  public get localUrl(): string {
    return this.url ? this.url : this._localUrl;
  }

  public get url(): string {
    return this.file ? this.file.url() : '';
  }

  public displayType() {
    return this.type ? this.type.split('/')[1] : '';
  }

  public updatePath() {
    this.path = this.parentPath + '/' + this.name;
  }

  public setFile(file: File, data?: any) {
    this.type = file.type;
    this.name = file.name;
    this.extension = fileExtension(file.name);
    this.size = file.size;
    this.set(FileRef.FILE_KEY, new Parse.File(file.name, data ? {base64: data} : file));
    this._localUrl = data;
  }

  public revert() {
    super.revert();
    this._localUrl = '';
  }
}

Parse.Object.registerSubclass(FileRef.CLASS_NAME, FileRef);
