import { ParseObject } from './parse-object';

import * as _ from 'lodash';

import { FileRef } from './file-ref';
import { Language } from './language';
import { Size } from './size';

export class Category extends ParseObject {
  public static readonly CLASS_NAME = 'Category';
  public static readonly PARENT_KEY = 'parent';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly NAME_KEY = 'name';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly IMAGE_KEY = 'image';

  constructor(id?: string) {
    super(Category.CLASS_NAME);
    this.id = id;
  }

  public get parent(): Category {
    return this.get(Category.PARENT_KEY);
  }

  public set parent(parent: Category) {
    this.set(Category.PARENT_KEY, parent);
  }

  public get sortOrder(): number {
    return this.get(Category.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Category.SORT_ORDER_KEY, sortOrder);
  }

  public get enabled(): boolean {
    return this.get(Category.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Category.ENABLED_KEY, enabled);
  }

  public get name(): string {
    return this.get(Category.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Category.NAME_KEY, name);
  }

  public get description(): string {
    return this.get(Category.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(Category.DESCRIPTION_KEY, description);
  }

  public get image(): FileRef {
    return this.get(Category.IMAGE_KEY);
  }

  public set image(image: FileRef) {
    this.set(Category.IMAGE_KEY, image);
  }

  public get thumbnailUrl() {
    return this.image ? this.image.localUrl : FileRef.NO_IMAGE_PATH;
  }

  public entries(): ParseObject[] {
    return _.compact([this.image, this]);
  }
}

Parse.Object.registerSubclass(Category.CLASS_NAME, Category);
