import { ProductImage } from './product-image';
import { Cascade } from './cascade';

import * as _ from 'lodash';

import { ProductVariant } from './product-variant';
import { ImageVideo } from './image-video';
import { Attachment } from './attachment';
import { FileRef } from './file-ref';
import { Vendor } from './vendor';
import { MetaTags } from './meta-tags';
import { ProductInput } from './product-input';

export class Product extends Cascade {
  public static readonly CLASS_NAME = 'Product';
  public static readonly VENDOR_KEY = 'vendor';
  public static readonly CATEGORIES_KEY = 'categories';
  public static readonly DISPLAY_ONLY_KEY = 'displayOnly';
  public static readonly SORT_ORDER_KEY = 'sortOrder';
  public static readonly ENABLED_KEY = 'enabled';
  public static readonly NAME_KEY = 'name';
  public static readonly UNIT_LABEL_KEY = 'unitLabel';
  public static readonly DESCRIPTION_KEY = 'desc';
  public static readonly DETAIL_KEY = 'detail';
  public static readonly VARIANTS_KEY = 'variants';
  public static readonly VIDEOS_KEY = 'videos';
  public static readonly SORT_PRICE_KEY = 'sortPrice';
  public static readonly SORT_ORIGINAL_PRICE_KEY = 'sortOriginalPrice';
  public static readonly TECHNICAL_BOOKS_KEY = 'technicalBooks';
  public static readonly THREE_DIMENSION_DRAWING_KEY = 'threeDimensionDrawings';
  public static readonly CERTIFICATES_KEY = 'certificates';
  public static readonly IES_KEY = 'ies';
  public static readonly INPUTS_KEY = 'inputs';
  public static readonly INSTALLATIONS_KEY = 'installations';
  public static readonly INSTALLATION_KEY = 'installation';
  public static readonly REQUIRED_COUPON_KEY = 'requiredCoupon';
  public static readonly REQUIRED_SERIAL_NUMBER_KEY = 'requiredSerialNumber';
  public static readonly REMOVED_KEY = 'removed';

  // public static readonly OVERVIEW_KEY = 'overview';
  public static readonly DEFAULT_VARIANT_KEY = 'defaultVariant';
  public static readonly VIEWS_KEY = 'views';
  public static readonly SALES_KEY = 'sales';
  public static readonly TABS_KEY = 'tabs';
  public static readonly IMAGE_VIDEOS_KEY = 'imageVideos';
  public static readonly METATAGS_KEY = 'metaTags';
  public static readonly TYPE_KEY = 'type';

  constructor(id?: string) {
    super(Product.CLASS_NAME);
    this.id = id;
  }

  static create() {
    const product = new Product()

    product.enabled = true;
    product.defaultVariant = new ProductVariant();
    product.variants = [];
    product.technicalBooks = [];
    product.threeDimensionDrawings = [];
    product.certificates = [];
    product.videos = [];
    product.installations = [];
    product.inputs = [];
    product.ies = [];
    product.tabs = [];
    product.type = 'main';

    return product
  }

  // ===============================================================================================
  // Accessors
  // ===============================================================================================

  public get unitLabel(): string {
    return this.get(Product.UNIT_LABEL_KEY);
  }

  public set unitLabel(unitLabel: string) {
    this.set(Product.UNIT_LABEL_KEY, unitLabel);
  }

  public get vendor(): Vendor {
    return this.get(Product.VENDOR_KEY);
  }

  public set vendor(vendor: Vendor) {
    this.set(Product.VENDOR_KEY, vendor);
  }

  public get displayOnly(): boolean {
    return this.get(Product.DISPLAY_ONLY_KEY);
  }

  public set displayOnly(displayOnly: boolean) {
    this.set(Product.DISPLAY_ONLY_KEY, displayOnly);
  }

  public get categories(): Parse.Relation {
    return this.relation(Product.CATEGORIES_KEY);
  }

  public get sortOrder(): number {
    return this.get(Product.SORT_ORDER_KEY);
  }

  public set sortOrder(sortOrder: number) {
    this.set(Product.SORT_ORDER_KEY, sortOrder);
  }

  public get enabled(): boolean {
    return this.get(Product.ENABLED_KEY);
  }

  public set enabled(enabled: boolean) {
    this.set(Product.ENABLED_KEY, enabled);
  }

  public get name(): string {
    return this.get(Product.NAME_KEY);
  }

  public set name(name: string) {
    this.set(Product.NAME_KEY, name);
  }

  public get description(): string {
    return this.get(Product.DESCRIPTION_KEY);
  }

  public set description(description: string) {
    this.set(Product.DESCRIPTION_KEY, description);
  }

  public get detail(): string {
    return this.get(Product.DETAIL_KEY);
  }

  public set detail(detail: string) {
    this.set(Product.DETAIL_KEY, detail);
  }

  public get variants(): ProductVariant[] {
    return this.get(Product.VARIANTS_KEY);
  }

  public set variants(options: ProductVariant[]) {
    this.set(Product.VARIANTS_KEY, options);
  }

  public get videos(): Attachment[] {
    return this.get(Product.VIDEOS_KEY);
  }

  public set videos(videos: Attachment[]) {
    this.set(Product.VIDEOS_KEY, videos);
  }

  public get certificates(): Attachment[] {
    return this.get(Product.CERTIFICATES_KEY);
  }

  public set certificates(certificates: Attachment[]) {
    this.set(Product.CERTIFICATES_KEY, certificates);
  }

  public get installations(): Attachment[] {
    return this.get(Product.INSTALLATIONS_KEY);
  }

  public set installations(installations: Attachment[]) {
    this.set(Product.INSTALLATIONS_KEY, installations);
  }

  public get technicalBooks(): Attachment[] {
    return this.get(Product.TECHNICAL_BOOKS_KEY);
  }

  public set technicalBooks(technicalBooks: Attachment[]) {
    this.set(Product.TECHNICAL_BOOKS_KEY, technicalBooks);
  }

  public get threeDimensionDrawings(): Attachment[] {
    return this.get(Product.THREE_DIMENSION_DRAWING_KEY);
  }

  public set threeDimensionDrawings(threeDimensionDrawings: Attachment[]) {
    this.set(Product.THREE_DIMENSION_DRAWING_KEY, threeDimensionDrawings);
  }

  public get ies(): Attachment[] {
    return this.get(Product.IES_KEY);
  }

  public set ies(ies: Attachment[]) {
    this.set(Product.IES_KEY, ies);
  }

  public get sortPrice(): number {
    return this.get(Product.SORT_PRICE_KEY);
  }

  public set sortPrice(sortPrice: number) {
    this.set(Product.SORT_PRICE_KEY, sortPrice);
  }

  public get sortOriginalPrice(): number {
    return this.get(Product.SORT_ORIGINAL_PRICE_KEY);
  }

  public set sortOriginalPrice(sortOriginalPrice: number) {
    this.set(Product.SORT_ORIGINAL_PRICE_KEY, sortOriginalPrice);
  }

  public get views(): number {
    return this.get(Product.VIEWS_KEY);
  }

  public set views(views: number) {
    this.set(Product.VIEWS_KEY, views);
  }

  public get sales(): number {
    return this.get(Product.SALES_KEY);
  }

  public set sales(sales: number) {
    this.set(Product.SALES_KEY, sales);
  }

  public get defaultVariant(): ProductVariant {
    return this.get(Product.DEFAULT_VARIANT_KEY);
  }

  public set defaultVariant(defaultVariant: ProductVariant) {
    this.set(Product.DEFAULT_VARIANT_KEY, defaultVariant);
  }

  public get installation(): string {
    return this.get(Product.INSTALLATION_KEY);
  }

  public set installation(installation: string) {
    this.set(Product.INSTALLATION_KEY, installation);
  }

  public get tabs(): any[] {
    return this.get(Product.TABS_KEY);
  }

  public set tabs(tabs: any[]) {
    this.set(Product.TABS_KEY, tabs);
  }

  public get requiredCoupon(): boolean {
    return this.get(Product.REQUIRED_COUPON_KEY);
  }

  public set requiredCoupon(requiredCoupon: boolean) {
    this.set(Product.REQUIRED_COUPON_KEY, requiredCoupon);
  }

  public get requiredSerialNumber(): boolean {
    return this.get(Product.REQUIRED_SERIAL_NUMBER_KEY);
  }

  public set requiredSerialNumber(requiredSerialNumber: boolean) {
    this.set(Product.REQUIRED_SERIAL_NUMBER_KEY, requiredSerialNumber);
  }

  public get imageVideos(): ImageVideo[] {
    return this.get(Product.IMAGE_VIDEOS_KEY);
  }

  public set imageVideos(imageVideos: ImageVideo[]) {
    this.set(Product.IMAGE_VIDEOS_KEY, imageVideos);
  }

  public get metaTags(): MetaTags {
    return this.get(Product.METATAGS_KEY);
  }

  public set metaTags(metaTags: MetaTags) {
    this.set(Product.METATAGS_KEY, metaTags);
  }

  public get thumbnailUrl() {
    let images = this.defaultVariant.images;
    return images && images.length ? images[0].localUrl : FileRef.NO_IMAGE_PATH;
  }

  public get defaultImageUrl() {
    return this.defaultVariant && this.defaultVariant.images && this.defaultVariant.images.length && this.defaultVariant.images[0] ? this.defaultVariant.images[0].url : '';
  }

  public variantsForEnabled(enabled: boolean = true) {
    return this.variants.filter(v => v.enabled == enabled);
  }

  public get available() {
    return !!this.createdAt && this.enabled;
  }

  public get inputs(): ProductInput[] {
    return this.get(Product.INPUTS_KEY);
  }

  public set inputs(inputs: ProductInput[]) {
    this.set(Product.INPUTS_KEY, inputs);
  }
  
  public get removed(): boolean {
    return this.get(Product.REMOVED_KEY);
  }

  public set removed(removed: boolean) {
    this.set(Product.REMOVED_KEY, removed);
  }

  public get type(): string {
    return this.get(Product.TYPE_KEY);
  }

  public set type(type: string) {
    this.set(Product.TYPE_KEY, type);
  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public displayVariant() {
    let variants = this.variantsForEnabled();

    return variants.length ? (_.minBy(variants, v => v.price) || variants[0]) : this.defaultVariant;
  }

  public images(key: string) {
    let variants = this.variantsForEnabled();

    return ProductImage.images(key, variants);
  }

  public firstImage(key: string, value: string) {
    let variantImages = this.images(key);
    let productImage = variantImages.find(i => i.value == value);
    return productImage ? productImage.images[0] : null;
  }

  public firstVariant(key: string, value: string) {
    let variants = this.variantsForEnabled();

    return variants.find(v => {
      return v.properties[key] == value;
    });
  }

  public firstVariantWithProperties(properties: any) {
    let variants = this.variantsForEnabled();

    return variants.find(v => {
      return _.isEqual(v.properties, properties);
    });
  }

  public entries() {
    let videos = this.videos ? _.flatten(this.videos.map(v => v.entries())) : [];
    let technicalBooks = this.technicalBooks ? _.flatten(this.technicalBooks.map(t => t.entries())) : [];
    let threeDimensionDrawings = this.threeDimensionDrawings ? _.flatten(this.threeDimensionDrawings.map(t => t.entries())) : [];
    let certificates = this.certificates ? _.flatten(this.certificates.map(c => c.entries())) : [];
    let ies = this.ies ? _.flatten(this.ies.map(c => c.entries())) : [];
    let variants = this.variants ? _.flatten(this.variants.map(v => v.entries())) : [];
    // let defaultVariants = this.defaultVariant ? this.defaultVariant.entries() : [];
    let inputs = this.inputs ? _.flatten(this.inputs.map(i => i.entries())) : []
    return _.concat(variants, videos, certificates, technicalBooks, ies, inputs, threeDimensionDrawings, this);
  }
}

Parse.Object.registerSubclass(Product.CLASS_NAME, Product);
