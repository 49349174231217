import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MetaModule } from '@ngx-meta/core';
import { LocalStorageModule } from 'angular-2-local-storage';

import { LocalStorageService, CrudService, CityService, ShippingZoneService, WindowRef, ConfigurationService, HelperService, UserService } from '@shared/services';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { ShareModule } from 'ngx-sharebuttons';

@NgModule({
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, {
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
}),
    TranslateModule.forRoot(),
    LocalStorageModule.forRoot({
      prefix: 'sge',
      storageType: 'localStorage'
    }),
    NgbModule,
    MetaModule.forRoot(),
    ShareModule
  ],
  declarations: [
    
    AppComponent
  ],
  providers: [
    UserService,
    WindowRef,
    HelperService,
    ConfigurationService,
    CrudService,
    CityService,
    ShippingZoneService,
    LocalStorageService,
    {provide: APP_BASE_HREF, useValue : '/' }
  ]
})
export class AppModule {

}
