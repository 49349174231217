import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { User } from '@shared/data';

@Injectable()
export class FavouriteService {
  private _subject: BehaviorSubject<any>
  private _favourite: Observable<any>


  constructor(
 
  ) {
    this._subject = new BehaviorSubject({count: 0, rows: []})
    this._favourite = this._subject.asObservable()
  }

  public favourite() {
    const user = User.current()
    
    if (user) {
      Parse.Cloud.run('favourite-find').then(
        result => {
          this._subject.next(result);
        }
      )
    } else {
      this._subject.next(of({count: 0, rows: []}))
    }    

    return this._favourite;
  }

  public async exist(productIds: string[]) {
    const user = User.current()
    console.log(user)
    
    if (!user) {
      return {}
    }

    return await Parse.Cloud.run('favourite-exist', { productIds })
  }

  public async remove(productIds: string[]) {
    await Parse.Cloud.run('favourite-remove', { productIds })
    
    const result = await Parse.Cloud.run('favourite-find')

    this._subject.next(result);
  }

  public async add(productId: string) {
    await Parse.Cloud.run('favourite-add', { productId })
    
    const result = await Parse.Cloud.run('favourite-find')

    this._subject.next(result);
  }
}
