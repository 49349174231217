import { Injectable } from '@angular/core';

import { ParseObject, FileRef, Cascade, Temporary } from '@shared/data';

import * as _ from 'lodash';

@Injectable()
export class CrudService {
  constructor() {

  }

  // ===============================================================================================
  // Public Methods
  // ===============================================================================================

  public destroy(o: ParseObject | ParseObject[]) {
    let entries = o instanceof Array ? _.flatten(o.map(o => this.entries(o))) : this.entries(o);

    console.log('Destroy: ' + entries.length);

    return Parse.Object.destroyAll(entries);
  }

  public revert(o: ParseObject | ParseObject[]) {
    let entries = o instanceof Array ? _.flatten(o.map(o => this.entries(o))) : this.entries(o);
    let changes = entries.filter(o => o.dirty() && !o.isNew());

    console.log('Revert: ' + changes.length);

    changes.forEach(o => o.revert());
  }

  public save(o: ParseObject | ParseObject[], temporary: boolean = false) {
    let entries = o instanceof Array ? _.flatten(o.map(o => this.entries(o))) : this.entries(o);
    let prevEntries = o instanceof Array ? _.flatten(o.map(o => this.prevEntries(o))) : this.prevEntries(o);
    let count = 0;

    entries.forEach(e => {
      if (this.isTemporary(e) && e.temporary != temporary) {
        e.temporary = temporary;
        count += 1;
      }
    });

    console.log('Temporary: ' + count + ' change to ' + temporary);

    let toDestroys = _.differenceBy(prevEntries, entries, 'id').filter(o => o.available);
    
    let toSaves = entries.filter(e => e.dirty());

    let saved: boolean = toSaves.length == 0;
    let deleted: boolean = toDestroys.length == 0;
    let promise = new Parse.Promise();

    console.log('Save: ' + toSaves.length);
    console.log('Destroy: ' + toDestroys.length);

    if (saved && deleted) {
      promise.resolve(null);
    }

    if (!saved) {
      ParseObject.saveAll(toSaves).then(
        (result) => {
          saved = true;
          if (deleted) promise.resolve(result);
        },
        (error: Parse.Error) => {
          promise.reject(error);
        }
      );
    }

    if (!deleted) {
      ParseObject.destroyAll(toDestroys).then(
        (result) => {
          deleted = true;
          if (saved) promise.resolve(result);
        },
        (error: Parse.Error) => {
          promise.reject(error);
        }
      );
    }

    return promise;
  }

  // ===============================================================================================
  // Private Methods
  // ===============================================================================================

  private entries(o: ParseObject) {
    return o instanceof Cascade ? o.entries() : [o];
  }

  private prevEntries(o: ParseObject) {
    return o instanceof Cascade ? o.prevEntries : [o];
  }

  private isTemporary(arg: any): arg is Temporary {
    return arg.temporary !== undefined;
  }
}
