  import { Injectable } from '@angular/core';

  import { Observable, Observer, Subscriber } from 'rxjs';
  import { fromPromise } from 'rxjs/observable/fromPromise';
  import * as _ from 'lodash';

  import './../extensions/parse+rx';

  import { ParseObservable } from './parse-observable';
  import { Product, Collection, ProductVariant, Category, ProductInput, ProductType } from './../data';
  import { ProductQuery } from './../queries';
  import { CollectionService } from './collection-service';
  import { CategoryService } from './category-service';
  import { publishReplay, refCount, switchMap } from 'rxjs/operators';
  import { FeatureProductService } from './feature-product-service';

  const PRODUCT_VIEW = 'product-view';

  interface ProductResult {
    rows: Product[];
    count: number;
    selected: Category
  }

  @Injectable()
  export class ProductService {
    private _findPopularProducts: Observable<ProductResult>
    private _mostPopular: Observable<Product[]>;
    private _firstMain: Observable<Product[]>;
    private _secondMain: Observable<Product[]>;
    private _thirdMain: Observable<Product[]>;
    private _newProduct: Observable<Product[]>;
  

    constructor(
      private collectionService: CollectionService, 
      private categoryService: CategoryService, 
      private featureProductService: FeatureProductService) {

    }

    // ===============================================================================================
    // Public Methods
    // ===============================================================================================

    public get(id: string) {
      let query = new ProductQuery()
        .includeAttachments()
        .includeVariants()
        .includeAttachments()
        .includeDefaultVariant()
        .enabled()
        .include([Product.INPUTS_KEY, ProductInput.UNITS_KEY].join('.'));

      return query.rx().get(id);
    }

    public findPopularProducts() {
      if (!this._findPopularProducts) {
        this._findPopularProducts = fromPromise(Parse.Cloud.run('product-popular', { limit: 15, type: ProductType.MAIN }))
      }
      return this._findPopularProducts
    }


    public mostPopular() {
      if (!this._mostPopular) {

        this._mostPopular = this.findPopularProducts().pipe(switchMap(value => {
          return Observable.of(value.rows).publishReplay(1).refCount()
        }))
      }
      return this._mostPopular;
    }

    public newProduct() {
      if (!this._newProduct) {
        let query = new ProductQuery()
          .includeDefaultVariant()
          .includeVariants()
          .enabled()
          .productType(ProductType.MAIN)
          .descending(Product.UPDATE_AT_KEY)
          .limit(5);

        this._newProduct = query.rx().find().pipe(publishReplay(1)).pipe(refCount());
      }
      return this._newProduct;
    }

    public async firstMain() {
      const category = await this.categoryService.firstMain().toPromise()
      const result = await this.featureProductService.find(category)
      return result.products;
    }

    public firstMainAt(index: number) {
      if (index == -1) {
        return this.firstMain();
      }
      return this.forSubcategory(this.categoryService.firstMain(), index);
    }

    public async secondMain() {
      const category = await this.categoryService.secondMain().toPromise()
      const result = await this.featureProductService.find(category)
      return result.products;
    }

    public secondMainAt(index: number) {
      if (index == -1) {
        return this.secondMain();
      }
      return this.forSubcategory(this.categoryService.secondMain(), index);
    }

    public async thirdMain() {
      const category = await this.categoryService.thirdMain().toPromise()
      const result = await this.featureProductService.find(category)
      return result.products;
    }

    public thirdMainAt(index: number) {
      if (index == -1) {
        return this.thirdMain();
      }
      return this.forSubcategory(this.categoryService.thirdMain(), index);
    }

    public view(productId: string) {
      return Parse.Cloud.run(PRODUCT_VIEW, { productId: productId });
    }

    // ===============================================================================================
    // Private Methods
    // ===============================================================================================

    private forCollection(collection: Observable<Collection>) {
      return collection.pipe(switchMap(o => {
        let query: ProductQuery = <ProductQuery>o.products.query()
          .include(Product.DEFAULT_VARIANT_KEY)
          .include([Product.DEFAULT_VARIANT_KEY, ProductVariant.IMAGES_KEY].join('.'))
          .include([Product.VARIANTS_KEY, [Product.VARIANTS_KEY, ProductVariant.IMAGES_KEY].join('.')])
          .equalTo(Product.ENABLED_KEY, true);

        return query.rx().find();
      })).pipe(publishReplay(1)).pipe(refCount());
    }

    private forCategory(category: Observable<Category>) {
      return category.pipe(switchMap(o => {
        let categories = _.compact(_.concat(o['children'], o));
        let query = new ProductQuery()
          .includeDefaultVariant()
          .includeVariants()
          .enabled()
          .notRemoved()
          .productType(ProductType.MAIN)
          .containedIn(Product.CATEGORIES_KEY,  categories)
          .descending(Product.UPDATE_AT_KEY);

        return query.rx().find();
      })).pipe(publishReplay(1)).pipe(refCount());
    }

    private forSubcategory(parent: Observable<Category>, index: number) {
      if (!parent['children']) {
        parent['children'] = [];
      }

      let products:Observable<Product[]> = parent['children'][index];

      if (products) {
        return products;
      }

      products = parent.pipe(switchMap(o => {
        let category = o['children'][index];
        let query = new ProductQuery()
          .includeDefaultVariant()
          .includeVariants()
          .enabled()
          .productType(ProductType.MAIN)
          .equalTo(Product.CATEGORIES_KEY, category)
          .descending(Product.UPDATE_AT_KEY);

        return query.rx().find();
      })).pipe(publishReplay(1)).pipe(refCount());

      parent['children'][index] = products;

      return products;
    }
  }
